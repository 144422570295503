import { faClock, faGlobe, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "prismjs/themes/prism.css";
import * as React from "react";

import HTMLContent from "./HTMLContent";
import Tags from "./Tags";

export interface Props {
  data: PropsData;
}

export interface PropsData {
  site: {
    siteMetadata: {
      siteUrl: string;
      title: string;
    };
  };
  markdownRemark: {
    id: string;
    html: string | React.Component;
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
      author: string;
      description: string;
      lang: string;
      templateKey: string;
      published: string;
      publishedDate: string;
      updated: string;
      updatedDate: string;
      tags: string[];
    };
  };
}

export const BlogPost: React.SFC<Props> = ({ data }) => {
  const post = data.markdownRemark;
  const updated = (post.frontmatter.updated && post.frontmatter.updated !== "Invalid date") ? <React.Fragment>&nbsp;<em>(last updated: <time dateTime={post.frontmatter.updatedDate}>{post.frontmatter.updated}</time>)</em></React.Fragment> : null;
  const content = (typeof post.html === "string") ? <HTMLContent html={post.html} /> : <div className="content">{post.html}</div>;

  return <React.Fragment>
    <div className="hero is-light is-bold">
      <div className="hero-body">
        <section className="container is-narrow">
          <h1 className="title">{post.frontmatter.title}</h1>
          <p>
            <span className="icon is-small"><FontAwesomeIcon icon={faUser} /></span>&nbsp;{post.frontmatter.author}
            &nbsp;-&nbsp;<span className="icon is-small"><FontAwesomeIcon icon={faClock} /></span>&nbsp;<time dateTime={post.frontmatter.publishedDate}>{post.frontmatter.published}</time>{updated}
            &nbsp;-&nbsp;<span className="icon is-small"><FontAwesomeIcon icon={faGlobe} /></span>&nbsp;{post.frontmatter.lang}
          </p>
          <Tags tags={post.frontmatter.tags} />
        </section>
      </div>
    </div>
    <div className="container is-narrow">
      <section className="section">
        {content}
      </section>
    </div>
  </React.Fragment>;
};

export default BlogPost;
