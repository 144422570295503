import * as React from "react";

interface Props {
  html: string;
}

const HTMLContent: React.SFC<Props> = ({ html }) => (
  <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
);

export default HTMLContent;
