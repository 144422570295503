import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import { default as BlogPost, Props} from "../components/BlogPost";
import Layout from "../components/Layout";

const BlogPostTpl: React.SFC<Props> = ({ data }) => {
  const post = data.markdownRemark;

  return <Layout>
    <Helmet titleTemplate={`%s - ${data.site.siteMetadata.title}`}>
      <html lang={data.markdownRemark.frontmatter.lang} />
      <title>{post.frontmatter.title}</title>
      <meta property="og:title" content={post.frontmatter.title} />
      <meta name="description" content={post.frontmatter.description} />
      <meta property="og:description" content={post.frontmatter.description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={`${data.site.siteMetadata.siteUrl}${post.fields.slug}`} />
    </Helmet>
    <BlogPost data={data} />
  </Layout>;
};

export default BlogPostTpl;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        author
        lang
        description
        templateKey
        published(formatString: "MMMM DD, YYYY")
        publishedDate: published(formatString: "YYYY-MM-DD")
        updated(formatString: "MMMM DD, YYYY")
        updatedDate: published(formatString: "YYYY-MM-DD")
        tags
      }
    }
  }
`;
