import { Link } from "gatsby";
import { kebabCase } from "lodash";
import * as React from "react";

export interface Props {
  tags: string[] | null;
}

const Tags: React.SFC<Props> = ({ tags }) => (tags === null) ? null : (
  <div className="tags">
    {tags.map(t => <React.Fragment key={t}><Link to={`/tags/${kebabCase(t)}/`} className="tag is-warning">{t}</Link></React.Fragment>)}
  </div>
);

export default Tags;
